/* Login */
.login_container {
  height: 100vh;
  background-image: linear-gradient(to bottom, #0f0c29, #302b63, #24243e);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loginsec {
  border: 2px solid #fdbb2d;
  border-radius: 20px;
  /* background-color: #fff; */
  background-image: linear-gradient(to bottom, #0f0c29, #302b63, #24243e);
}
.login_submitbtn {
  background-color: #fdbb2d;
  padding: 10px 30px;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bolder;
  font-size: 16px;
  border: none;
}
.login_submitbtn:hover {
  background-color: #ffae00;
}
.toast-error {
  background-color: #ff4444;
  color: #fff;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
}
